import type { DefaultConfigOptions } from '@formkit/vue'
import { de } from '@formkit/i18n'

const config: DefaultConfigOptions = {
  locales: { de },
  locale: 'de',
  messages: {
    de: {
      validation: {
        accepted() {
          return `Sie müssen den Teilnamebedingungen zustimmen.`
        },
        required({ name }) {
          name = name.replace(/\s*\*$/, '')
          return `Das Feld "${name}" ist ein Pflichtfeld.`
        },
        mime() {
          return `Dieses Dokument konnte nicht hochgeladen werden. Es werden nur ausgewählte Video-, Bild- und Textdateien akzeptiert.`
        },
      },
    },
  },
}

export default config
