import type { FileUploadResult, ReaderSuggestionsFormValues } from '../types';

export const createReaderSuggestionApi = async (
  apiUrl: string,
  apiToken: string
): Promise<number> => {
  const formData = new FormData();
  formData.append('api_token', apiToken);
  const response = await fetch(`${apiUrl}/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: apiToken,
    }),
  });
  const result = await response.json();
  return result.id;
};

export const storeReaderSuggestionApi = async (
  apiUrl: string,
  _apiToken: string,
  data: ReaderSuggestionsFormValues
): Promise<any> => {
  const storeData = {
    ...data,
    sugg_files: data.sugg_files || [],
    sugg_referrer: window ? window.location.href : null,
  };
  const response = await fetch(`${apiUrl}/save`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(storeData),
  });
  return await response.json();
};

export const uploadFileApi = async (
  apiUrl: string,
  apiToken: string,
  file: File,
  additionalValues: { [key: string]: string | Blob } = {}
): Promise<any> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('api_token', apiToken);
  Object.keys(additionalValues).forEach((k) =>
    formData.append(k, additionalValues[k])
  );
  const response = await fetch(`${apiUrl}/attachment`, {
    method: 'POST',
    body: formData,
  });
  return await response.json();
};

export const deleteFileApi = async (
  apiUrl: string,
  apiToken: string,
  file: FileUploadResult
): Promise<any> => {
  const response = await fetch(`${apiUrl}/attachment`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: apiToken,
      edit_token: file.edit_token,
      att_id: file.att_id,
    }),
  });
  return await response.json();
};
